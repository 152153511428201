import { takeEvery, put } from 'redux-saga/effects';
import { clearBankSelection, selectBank } from 'slices/banks';

import { Types } from 'actions/banks';
import { fetchSaga } from 'api/fetchSaga';

import { config } from 'config';

import { trackBankSearch } from 'utils/eventTracking';

const { apis } = config;

const LIMIT = 100;

export function* bankSelectionSaga({ query }) {
  const params = {
    path: apis.banks,
    params: {
      q: query,
      limit: LIMIT
    }
  };

  trackBankSearch(query);

  return yield fetchSaga({
    params,
    actionType: Types.GET_BANK_SELECTIONS,
    fields: 'banks'
  });
}

export function* preSelectBank({ query }) {
  yield put(clearBankSelection());
  const response = yield bankSelectionSaga({ query });

  if (response) {
    const bank = response.find(_bank => _bank.bic === query);
    if (bank) {
      yield put(selectBank({ selectedBank: bank }));
    }
  }
}

export function* banksSaga() {
  yield takeEvery(Types.GET_BANK_SELECTIONS, bankSelectionSaga);
  yield takeEvery(Types.PRE_SELECT_BANK, preSelectBank);
}
