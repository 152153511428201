export const formatIban = (iban = '') => {
  return iban
    .replace(/[^\dA-Za-z]/g, '') // Remove non digits/letters
    .replace(/(.{4})/g, '$1 ') // Add space after each 4 characters
    .toUpperCase()
    .trim();
};

export const getNextIbanCursorPosition = ({
  initialLength,
  value,
  currentPosition
}: {
  initialLength: number;
  value: string;
  currentPosition: number;
}) => {
  let position = currentPosition;

  if (
    value.charAt(position - 1) === ' ' &&
    value.charAt(initialLength - 1) === ' ' &&
    initialLength !== value.length
  ) {
    position += 1;
  }

  return position;
};

export const formatIbanAndUpdateEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
  const formattedTarget = event.target;
  const position = formattedTarget.selectionEnd ?? 0;

  const initialLength = formattedTarget.value.length;
  const formattedValue = formatIban(formattedTarget.value);

  formattedTarget.value = formattedValue;

  const isValuePasted = formattedValue.length - initialLength > 1;

  formattedTarget.selectionEnd = isValuePasted
    ? position
    : isValuePasted
    ? position
    : getNextIbanCursorPosition({
        initialLength,
        value: formattedValue,
        currentPosition: position
      });

  event.target = formattedTarget;

  return event;
};
