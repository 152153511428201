import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Done from '@mui/icons-material/Done';
import LanguageIcon from '@mui/icons-material/Language';
import find from 'lodash/find';
import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { connect, ConnectedProps } from 'react-redux';
import { t } from 'locale';

import { RootState } from 'store/store';
import { DrawerItem } from 'components/Drawer/DrawerItem';
import { DrawerTitle, DrawerSubtitle } from 'components/Drawer/DrawerStyled';
import { Catalan } from 'components/Icons/Catalan';
import { Creators, GetLocale } from 'locale/actions';
import { languageSelector } from 'selectors/localeSelector';
import { LocaleKey } from 'locale/types';

import { localeKeys } from 'enums/localeKeys';
import { countryCodes } from 'enums/keys';
import { anchorOrigin } from 'enums/variants';

import { useIsLoaded } from 'hooks/useIsLoaded';

import {
  IconButtonText,
  MenuItemButton,
  MenuItemWrapper,
  CountryFlagWrapper
} from '../AppBarStyled';

interface CountryFlagProps {
  language: string;
  dataId: string;
}

const CountryFlag = ({ language, dataId, ...rest }: CountryFlagProps) => {
  const countryCode = language === countryCodes.en ? countryCodes.gb : language;

  return (
    <CountryFlagWrapper>
      {countryCode === countryCodes.ca ? (
        <Catalan dataId={dataId} {...rest} />
      ) : (
        <ReactCountryFlag
          svg
          alt={language}
          countryCode={countryCode}
          style={{
            width: '18px',
            height: '18px'
          }}
          data-id={dataId}
          {...rest}
        />
      )}
    </CountryFlagWrapper>
  );
};

const languageTranslationsMap: Record<string, { title: LocaleKey; subtitle: LocaleKey }> = {
  english: {
    title: localeKeys.languageMenuEnglish,
    subtitle: localeKeys.languageMenuEnglishNative
  },
  german: {
    title: localeKeys.languageMenuGerman,
    subtitle: localeKeys.languageMenuGermanNative
  },
  spanish: {
    title: localeKeys.languageMenuSpanish,
    subtitle: localeKeys.languageMenuSpanishNative
  },
  catalan: {
    title: localeKeys.languageMenuCatalan,
    subtitle: localeKeys.languageMenuCatalanNative
  },
  french: {
    title: localeKeys.languageMenuFrench,
    subtitle: localeKeys.languageMenuFrenchNative
  }
};

interface LanguageMenuProps extends AppBarLanguageMenuPropsFromRedux {
  showLabel?: boolean;
}

const LanguageMenu = ({
  showLabel = false,
  allowedLanguages,
  currentLanguage,
  getLocale
}: LanguageMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const isOpen = Boolean(anchorEl);

  useIsLoaded(() => {
    setDocumentLang(currentLanguage);
  });

  const setDocumentLang = (language: string) => {
    document.documentElement.lang = language;
  };

  const openLanguageMenu = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    if (allowedLanguages.length > 0) {
      setAnchorEl(currentTarget);
    }
  };

  const closeLanguageMenu = () => setAnchorEl(null);

  const changeLanguage = (language: string) => {
    closeLanguageMenu();
    getLocale(language);
    setDocumentLang(language);
  };

  const getLabelByValue = (value: string) => {
    const language = find(allowedLanguages, lang => lang.value === value);
    return language ? language.label : value;
  };

  return (
    <React.Fragment>
      {showLabel ? (
        <DrawerItem
          to=""
          onClick={openLanguageMenu}
          title={getLabelByValue(currentLanguage)}
          icon={<LanguageIcon />}
        />
      ) : (
        <IconButtonText size="large" onClick={openLanguageMenu} data-id="app-bar-language-menu">
          {currentLanguage}
        </IconButtonText>
      )}

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeLanguageMenu}
        anchorOrigin={{
          vertical: anchorOrigin.bottom,
          horizontal: anchorOrigin.right
        }}
        // @ts-ignore
        MenuListProps={{ 'data-id': 'language-menu' }}
      >
        {allowedLanguages.map(({ label, value }, index) => (
          <MenuItem key={value} onClick={() => changeLanguage(value)}>
            <MenuItemButton data-id={`language-menu-item-${index}`}>
              <MenuItemWrapper>
                <CountryFlag language={value} dataId={`language-menu-item-flag-${index}`} />
                <div data-id={`language-menu-item-text-wrapper-${index}`}>
                  <DrawerTitle data-id={`language-menu-item-title-${index}`}>
                    {t(languageTranslationsMap[label]?.title ?? label)}
                  </DrawerTitle>
                  {value !== currentLanguage && (
                    <DrawerSubtitle data-id={`language-menu-item-subtitle-${index}`}>
                      {t(languageTranslationsMap[label]?.subtitle ?? '')}
                    </DrawerSubtitle>
                  )}
                </div>
                {value === currentLanguage && <Done data-id="language-menu-item-selected-icon" />}
              </MenuItemWrapper>
            </MenuItemButton>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  const { options, lang } = languageSelector(state);

  return {
    allowedLanguages: options,
    currentLanguage: lang
  };
};

const connector = connect(mapStateToProps, {
  getLocale: Creators.getLocale as GetLocale
});

type AppBarLanguageMenuPropsFromRedux = ConnectedProps<typeof connector>;

export const AppBarLanguageMenu = connector(LanguageMenu);
